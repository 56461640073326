<!-- Localized -->
<template>
  <div class="email-thread" :style="cssVars">
    <div class="email-thread__header">
      <div class="title">{{ $t('components.dashboard.email.email_trail') }}</div>
      <div class="close">
        <closeIcon class="close__icon" @click="$emit('close')" />
      </div>
    </div>
    <div class="content-wrapper">
      <!-- <div class="label">
      <div class="icon-wrapper">
        <component class="icon" :is="icon" />
      </div>
      <div class="name">
        {{ data.source_name }}
      </div>
    </div> -->
      <div
        v-if="dataT.title && dataT.title.length"
        class="email-thread__labels gap-4"
      >
        <div
          class="label gap-2"
          v-for="label in dataT.title"
          :key="label.value"
          :style="{
            backgroundColor: label.color,
          }"
        >
          <div class="icon">
            <component class="h-4 2xl:h-5" :is="icons[label.type]" />
          </div>
          <div class="name">
            <slot name="label" :label="label.value">
              {{ label.value }}
            </slot>
          </div>
        </div>
      </div>
      <div class="email-thread__content scroll-bar">
        <div class="body">
          <thread-item
            class="body__item"
            v-for="item in thread"
            :key="item.id"
            @toggle="toggleMessage(item.id)"
            :item="item"
            :opened="selected.includes(item.id)"
            @showEmailAttachments="showEmailAttachments($event)"
          />
        </div>
      </div>
    </div>
    <button class="button-collapse h-7" @click="clearSelected">
      <collapseIcon class="button-collapse__icon" />
      {{ $t('components.dashboard.email.collapse_thread') }}
    </button>

    <AttachmentsModal :isLoading="loadingStates.attachments" modalTitle="Attachments" :showSearch="false" :lists.sync="attachments" ref="email-trail-attachments">
        <template #item="{ item }">
            <div
              class="text-primary"
              @click="handleClickAttachments(item)"
              v-html="$t('components.dashboard.email.ordered_name', { index: item.index, name: item.name })"
            />
        </template>
    </AttachmentsModal>
  </div>
</template>

<script>
import threadItem from "./thread-item.vue";
import axios from "@/axios";
const closeIcon = () => import("@/assets/icons/ico-close-grey.svg");
const collapseIcon = () => import("@/assets/icons/dashboard/collapse-all.svg");

/* Label Icons */
const briefcaseIcon = () =>
  import("@/assets/icons/dashboard/label/briefcase.svg");
const graduateIcon = () =>
  import("@/assets/icons/dashboard/label/graduate.svg");
const AttachmentsModal = () => import("@shared/components/listModal");

export default {
  name: "email-thread",
  components: {
    closeIcon,
    collapseIcon,
    threadItem,
    AttachmentsModal,
  },
  props: {
    dataT: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selected: [],
      thread: [],
      icons: {
        employment: briefcaseIcon,
        education: graduateIcon,
      },
      attachments: [],
      loadingStates:{
          attachments: false
      }
    };
  },

  computed: {
    cssVars() {
      return {
        "--color-header": this.dataT.title?.[0]?.color || "#282828",
      };
    },
  },
  methods: {
    async fetchEmailTrail() {
      let url = `email/trail/${this.dataT.email_trail_id}`;
      let { data } = await axios.get(url);
      try {
        let emailThread = data.email_trail.map((x) => ({
          ...x,
          activity_type: "email",
          actions: ["reply", "check"],
        }));
        this.thread = emailThread;
      } catch (error) {
        console.log(error, "Error");
      }
    },
    showEmailAttachments({data, type}) {
        this.attachments = data?.attachments?.map((el, index) => ({id: el.key,index:index + 1, name: el.key.substring(el.key.lastIndexOf('/') + 1) })) || [];
        console.log(this.attachments, type);
        this.$refs[type].showListModal();
    },
    async handleClickAttachments(item) {
        this.loadingStates.attachments = true
        try {
          let { data } = await axios.get(`/email/attachment/url?key=${item.id}`)
          window.open(data.url, "_blank");
        } catch(error) {
          console.log(error)
        }
        this.loadingStates.attachments = false
    },
    toggleMessage(id) {
      if (this.selected.includes(id))
        this.selected = this.selected.filter((el) => el !== id);
      else this.selected.push(id);
    },
    clearSelected() {
      this.selected = [];
    },
  },
  async beforeMount() {
    await this.fetchEmailTrail();
    // console.log("beforemOunt email-thread dataT", this.dataT.email_trail_id);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/functions.scss";
@import "@/assets/mixins/mixins.scss";

.email-thread {
  display: flex;
  flex-direction: column;
  padding: 22px 40px 40px;
  background-color: #fff;
  border-radius: 24px;
  width: 100%;
  gap: 40px;
  position: relative;
  max-height: max-content;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-weight: 600;
      font-size: toRem(22px);
    }
    .close {
      width: 22px;
      height: 22px;
      cursor: pointer;
      &__icon {
        path {
          fill: #282828;
        }
      }
    }
  }
  &__labels {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: toRem(20px);
    margin-bottom: toRem(-22px);
    .label {
      // position: absolute;
      // top: 0;
      // left: 20px;
      // transform: translateY(-50%);
      flex-shrink: 0;
      align-self: flex-start;
      color: #fff;
      background-color: var(--color-header);
      border-radius: 12px;
      padding: 0 18px;
      min-width: 145px;
      font-size: 1rem;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      font-weight: 600;
      box-sizing: border-box;
      height: 44px;
      z-index: 1;
      .icon {
        svg {
          fill: #fff;
        }
      }
      .name {
        display: inline-flex;
        align-items: center;
        gap: 12px;
      }
    }
  }
  .content-wrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  &__content {
    padding: 40px;
    border: 1px solid var(--color-header);
    border-radius: 24px;
    // display: flex;
    // align-items: center;
    // gap: 28px;
    position: relative;
    overflow: auto;
    .body {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
      &__item {
        padding-bottom: 16px;
        &:not(:last-child) {
          border-bottom: 1px solid #a7a7a7;
        }
      }
    }
  }
  .button-collapse {
    @include buttonBase(#fff, var(--color-header), var(--color-header));
    position: absolute;
    transform: translate(-50%, 50%);
    left: 50%;
    bottom: 0;
  }
}
</style>
